<style scoped>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: letter portrait;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
    }

    .page-break {
        page-break-before: always;
        counter-reset: page;
    }
}

#logo {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 200px;
    height: 100px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

th.no-border {
    border: none;
}

.remove_border tr th {
    border: none;
}

#table-list {
    margin-top: 80px;
}

.info {
    margin-top: 20px;
    width: 40%;
    float: left;
    margin-bottom: 20px;
}

.space-for-logo {
    width: 200px;
    height: 100px;
}

#page-counter {
    display: table-footer-group;
}

#page-counter:after {
    counter-increment: page;
    content: " " counter(page) " of " counter(page);
}

.signature-table {
    page-break-inside: avoid;
    margin-top: 20px;
}
</style>

<template>
    <div>
        <div class="page-break">
            <table class="" style="width: 100%">
                <thead>
                    <tr class="">
                        <th colspan="7">
                            <div class="title">
                                <p>RECEIVE CANVAS</p>
                                <p>{{ companyName }}</p>
                            </div>
                        </th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Document</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ unlCode }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Print</th>
                        <th class="py-0 w-1" colspan="2">: {{ printCounter }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Cabang</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ selectedWarehouse ? selectedWarehouse.label : 'Loading..' }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Print date</th>
                        <th class="py-0 w-1" colspan="2">: {{ dateNow }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Tanggal Request</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ formatDate(requestDate) }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Print time</th>
                        <th class="py-0 w-1" colspan="2">: {{ timeNow }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Tanggal Loading</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ formatDate(loadingDate) }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5"></th>
                        <th class="py-0 w-1" colspan="2"></th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Tanggal Unloading</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ formatDate(unloadingDate) }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5"></th>
                        <th class="py-0 w-1" colspan="2"></th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Salesman</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ selectedSales ? selectedSales.label : 'Loading..' }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5"></th>
                        <th class="py-0 w-1"></th>
                    </tr>
                    <tr>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-1"></th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5"></th>
                        <th class="py-0 w-1"></th>
                        <th class="py-0 w-5"></th>
                    </tr>
                </thead>
            </table>
            <br>
            <!-- ... -->
            <table class="" style="width: 100%;">
                <thead>
                    <tr style="border-top: 1px dashed; border-bottom: 1px dashed">
                        <th style="vertical-align: middle; text-align: left">Sku Code</th>
                        <th style="vertical-align: middle; text-align: left">Sku Description</th>
                        <th style="vertical-align: middle; text-align: left">Qty Loading</th>
                        <th style="vertical-align: middle; text-align: left">Qty Sales</th>
                        <th style="vertical-align: middle; text-align: left">Remain Qty</th>
                        <th style="vertical-align: middle; text-align: left">Unload Qty</th>
                        <th style="vertical-align: middle; text-align: left">UOM</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(tr, index) in dataLines" :key="index" style="padding-bottom: 5px">
                        <td class="py-1" style="padding-left: 10px;">{{ tr.item_code }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.item_desc }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.qty_loading }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.qty_sales }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.qty_remaining }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.ul_qty }}</td>
                        <td class="py-1" style="padding-left: 10px;">{{ tr.uom }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr style="border-top: 1px dashed">
                        <td colspan="6"></td>
                    </tr>
                </tfoot>
            </table>
            <br>
            <!-- ... -->
            <table class="remove_border signature-table" style="page-break-before: auto">
                <tr>
                    <th style="text-align: center">Dibuat oleh</th>
                    <th style="text-align: center">Diserahkan oleh</th>
                    <th style="text-align: center">Diterima oleh</th>
                </tr>
                <tr>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                </tr>
                <tr>
                    <th style="text-align: center">Admin Logistic</th>
                    <th style="text-align: center">Salesman</th>
                    <th style="text-align: center">Checker</th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";

export default {
    name: "PrintUnloadingList",
    data() {
        return {
            id: 0,
            reqCode: "Loading..",
            handoverCode: "Loading..",
            unlCode: "Loading..",
            companyName: "Loading..",
            selectedTerritory: null,
            selectedWarehouse: null,
            selectedSales: null,
            printCounter: 0,
            requestDate: "Loading..",
            loadingDate: "Loading..",
            unloadingDate: "Loading..",
            dataLines: [
                {
                    unloading_id: 0,
                    item_name: null,
                    item_code: null,
                    item_desc: null,
                    uom: null,
                    qty_loading: 0,
                    qty_sales: 0,
                    qty_remaining: 0,
                    ul_option_hu: [],
                    ul_selected_hu: "",
                    ul_qty: 0,
                    qty_uom: null,
                }
            ],
            dateNow: "",
            timeNow: "",
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.print()
        }
    },
    methods: {
        async print() {
            await this.getData()
            await window.print();
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get("/api/wms/v1/canvas/unloading/receive-data-form/" + this.id + "/" + true)
            if (resp.code == 200) {
                this.reqCode = resp.data.request_doc
                this.handoverCode = resp.data.handover_doc
                this.unlCode = resp.data.unloading_doc
                this.companyName = resp.data.company_name
                this.printCounter = resp.data.print_counter
                this.selectedTerritory = resp.data.selected_territory
                this.selectedWarehouse = resp.data.selected_warehouse
                this.selectedSales = resp.data.selected_sales
                this.requestDate = moment(resp.data.request_date).toDate()
                this.loadingDate = moment(resp.data.loading_date).toDate()
                this.unloadingDate = moment(resp.data.unloading_date).toDate()
                this.dataLines = resp.data.data_lines
                // ...
                const now = new Date();
                this.dateNow = this.formatDate(now.setHours(now.getHours() + 7));
                this.timeNow = this.formatTime(now);
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format("YYYY-MM-DD");
            };
        },
        formatTime: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format("hh:mm:ss");
            };
        },
    },
}
</script>